import styled from 'styled-components'

export default styled.div`
  display: flex;
  justify-content: center;
  .button-group-wrapper {
    border: 1px solid #981214;
    width: 189px;
    height: 24px;
    display: flex;
    .each-button {
      width: 95px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      &:hover {
        cursor: pointer;
      }
      &.active {
        background: #981214;
        &:hover {
          cursor: default;
        }
      }
    }
  }
`
