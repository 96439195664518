import React from 'react'
import PropTypes from 'prop-types'
import ButtonGroupStyled from './styled'

class ButtonGroup extends React.PureComponent {
  state = {
    activeButton: '',
  }

  onButtonClick = value => {
    this.setState({
      activeButton: value,
    })
    this.props.onEachButtonClick(value)
  }

  renderButtons() {
    const { buttonList, activeSortBy } = this.props
    const output = buttonList.map(button => {
      let className = 'each-button'
      const isActiveButton = activeSortBy === button.value
      if (isActiveButton) {
        className += ' active'
      }
      return (
        <div key={button.value} className={className} onClick={() => this.onButtonClick(button.value)}>
          {button.text}
        </div>
      )
    })
    return <div className="button-group-wrapper">{output}</div>
  }

  render() {
    return <ButtonGroupStyled className={this.props.className}>{this.renderButtons()}</ButtonGroupStyled>
  }
}

ButtonGroup.propTypes = {
  className: PropTypes.string,
  buttonList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  activeSortBy: PropTypes.string.isRequired,
  onEachButtonClick: PropTypes.func,
}

export default ButtonGroup
